import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Grooming & Bathing Services" keywords={[`New Hope Pet Center`, `New Hope`, `Pet Center`, `Pet Grooming`, `Pet Supplies`]} />
    <div>
      <article className="cf ph3 pv4 mw8 center">
        <section className="fn fl-ns w-50-ns pr4-ns lh-copy">
          <h1 className="f3 f2-ns lh-title fw9 mb3 mt0 pt3 bb-ns b--light-gray bw2">Dog and Cat Grooming Service</h1>
          <ul className="f3 mid-gray lh-title list pl0 pb3 bb b--light-gray">
            <li className="flex items-center pv2">
              <i className="dib material-icons green f3 pr2">check_circle</i>Professional
                </li>
            <li className="flex items-center pv2">
              <i className="dib material-icons green f3 pr2">check_circle</i>Experienced
                </li>
            <li className="flex items-center pv2">
              <i className="dib material-icons green f3 pr2">check_circle</i>By Appointment Only
                </li>
          </ul>
          <h2>
            Call <a href="tel:2158622778" className="link black bb">(215) 862-2778</a> to schedule an appointment.
             </h2>
          <p className="bg-washed-blue blue pa3 ba br3 b--lightest-blue">
            <i className="material-icons f1 fl pr2">info_outline</i>We are usually booked at least one week in advance.
                <br />
            <b>Call for availability.</b>
          </p>
          <p>Grooming available Monday through Friday</p>
          <p>
            <b>Drop off times:</b> Between 8:00 a.m. and approximately 11:30 a.m.
             </p>
          <p>
            <b>Pick up:</b>Determined at time of drop off.
             </p>
          <p>Pets may be dropped off before the appointment time, and can stay longer, but must be picked up before closing to avoid additional charges.</p>
        </section>
        <div className="fn fl-ns w-50-ns">
          <img alt="Our Groomers" src={require("../images/sue.jpg")} className="mw-100 pa2-ns" />
          <p className="f5 lh-copy measure mt0-ns">
            Sue, the owner, is a graduate of Pennsylvania School of Dog Grooming.
                <br /><br />
                She has been grooming dogs and cats for over 35 years. In addition, our other Experienced Professional groomers include Kaylah, Mary, and Ashlee. New Hope Pet Center's highly professional staff will give your Dog, or Cat, the pampered, wonderful experience they deserve.
             </p>
        </div>
      </article>
    </div>

  </Layout>
)

export default Page
